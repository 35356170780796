import * as React from 'react';
import { SkeletonLoader } from 'design-system-kit';
import { clsx } from 'clsx';
import { Loader as ProductBadgeLoader } from '../product-badge';
import styles from './add-on-detail.strict-module.css';
import loaderStyles from './loader.strict-module.css';

function LoaderTop(): React.JSX.Element {
  return (
    <>
      <div className={loaderStyles.top}>
        <div className="mb-16">
          <ProductBadgeLoader />
        </div>

        <SkeletonLoader.Line height="16px" width="186px" />
      </div>

      <div className="mb-20">
        <div className={clsx('mb-20', loaderStyles.price)}>
          <SkeletonLoader.Line height="12px" width="64px" />
          <SkeletonLoader.Line width="170px" />
        </div>

        <SkeletonLoader.Line width="162px" />
      </div>
    </>
  );
}

function LoaderFeatures(): React.JSX.Element {
  return (
    <>
      <div className="mb-8">
        <SkeletonLoader.Line height="12px" width="180px" />
      </div>

      <SkeletonLoader.Line width="180px" />
      <SkeletonLoader.Line width="180px" />
      <SkeletonLoader.Line width="180px" />
      <SkeletonLoader.Line width="180px" />
      <SkeletonLoader.Line width="180px" />
      <SkeletonLoader.Line width="180px" />
      <SkeletonLoader.Line width="180px" />
    </>
  );
}

export function Loader(): React.JSX.Element {
  return (
    <div className={styles.container}>
      <LoaderTop />

      <LoaderFeatures />
    </div>
  );
}

export function LoaderWithButton(): React.JSX.Element {
  return (
    <div className={styles.container} data-test-add-on-detail-loader-with-button>
      <LoaderTop />

      <SkeletonLoader.Block height="32px" width="100%" />

      <LoaderFeatures />
    </div>
  );
}
