const safeLocalStorage = {
  /**
   * Sets an item in localStorage.
   * @param {string} key - The key of the item.
   * @param {string} value - The value of the item.
   * @returns {boolean} - Returns true if the item was set successfully, otherwise false.
   */
  setItem(key, value) {
    try {
      window.localStorage.setItem(key, value);
      return true;
    } catch (error) {
      console.error("Error setting item in localStorage:", error);
      return false;
    }
  },

  /**
   * Retrieves an item from localStorage.
   * @param {string} key - The key of the item.
   * @returns {string|null} - Returns the value of the item, or null if an error occurred.
   */
  getItem(key) {
    try {
      return window.localStorage.getItem(key);
    } catch (error) {
      console.error("Error retrieving item from localStorage:", error);
      return null;
    }
  },

  /**
   * Removes an item from localStorage.
   * @param {string} key - The key of the item.
   * @returns {boolean} - Returns true if the item was removed successfully, otherwise false.
   */
  removeItem(key) {
    try {
      window.localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.error("Error removing item from localStorage:", error);
      return false;
    }
  },
};

module.exports = safeLocalStorage;
