import type { ReactElement, SVGProps } from 'react';

export function BadgeLock(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.633 5.389v1.669H3.79a.59.59 0 0 0-.59.594v5.754c0 .328.263.594.59.594h8.419c.326 0 .59-.266.59-.594V7.652a.59.59 0 0 0-.59-.594h-.842v-1.67C11.368 3.519 9.86 2 8 2S4.633 3.517 4.633 5.389m5.05 0v1.669H6.317v-1.67A1.69 1.69 0 0 1 8 3.695c.93 0 1.684.76 1.684 1.695"
        fill="currentColor"
      />
    </svg>
  );
}
