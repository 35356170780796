import type { ReactElement } from 'react';
import cx from 'clsx';
import type {
  TextAreaProps as AriaTextAreaProps} from 'react-aria-components';
import {
  TextArea as AriaTextArea
} from 'react-aria-components';
import inputStyles from '../input/input.strict-module.css';
import styles from './text-area.strict-module.css';

interface TextAreaProps extends AriaTextAreaProps {
  isResizable?: boolean;
}

export function TextArea({ className, isResizable, ...props }: TextAreaProps): ReactElement {
  return (
    <AriaTextArea
      className={cx(className, inputStyles['input-field'], !isResizable && styles.fixed)}
      {...props}
    />
  );
}
