const safeLocalStorage = require("../helper/safeStorage");
const storageAvailable = require("../helper/storage-available");

/**
 * @param {Object} runTimeEnvironment - The environment configuration object.
 * @param {string} environment.backendBranchName - The backend branch name.
 * @param {string} environment.environment - The environment name.
 * @param {string} environment.frontendBranchName - The frontend branch name.
 * @param {string} environment.frontendEksClusterId - The frontend EKS cluster ID.
 * @param {string} environment.monorepoSuffix - The monorepo suffix.
 * @param {boolean} environment.useQontoEnv - Flag to use Qonto environment.
 * @param {boolean} environment.isStaging - Flag to indicate if it's a staging environment.
 * @param {string} environment.eksClusterId - The EKS cluster ID.
 * @returns {Object} - The URLs table for the given environment.
 */
module.exports = {
  getUrlsTable(runTimeEnvironment) {
    const {
      backendBranchName,
      frontendBranchName,
      frontendEksClusterId,
      monorepoSuffix,
      frontendUrlMode,
      backendUrlMode,
      eksClusterId,
    } = runTimeEnvironment;

    const stagingBackendBranchName =
      (storageAvailable("localStorage") &&
        safeLocalStorage.getItem("QONTO_BACKEND_BRANCH_NAME")) ||
      backendBranchName ||
      "qa";
    const stagingBackendBranchDomain = `staging${
      (storageAvailable("localStorage") &&
        safeLocalStorage.getItem("QONTO_CLUSTER_ID")) ||
      eksClusterId
    }.qonto.co`;

    const stagingFrontendBranchDomain = `staging${frontendEksClusterId}.qonto.co`;
    const stagingFrontendBranchName = `${monorepoSuffix}${frontendBranchName}`;

    let backendUrlsTable = {
      local: {
        announcementBaseURL:
          "https://firestore.googleapis.com/v1/projects/announcement-staging/databases/(default)/documents",
        apiBaseURL: "https://api.dev.qonto.co",
        attachmentCollectorURL: `https://attachment-collector.dev.qonto.co`,
        authBaseURL: "https://auth.dev.qonto.co",
        billerBaseURL: "https://biller.dev.qonto.co",
        companyCreationBaseURL: "https://company-creation-de.dev.qonto.co",
        hubBaseURL: "https://hub.dev.qonto.co",
        notifierBaseURL: "https://notifier-api.dev.qonto.co",
        pricingCatalogBaseURL: "https://pricing-catalog.dev.qonto.co",
        registerBaseURL: "https://register.dev.qonto.co",
        registerPartnersBaseURL: "https://partner-register.dev.qonto.co",
        verificationBaseURL: "https://verification.dev.qonto.co",
        switchingBankApiBaseURL: "https://switching-bank-api.dev.qonto.co",
        websocketBaseURL: "ws://websocket.dev.qonto.co",
        landingURL: "https://qonto.com",
        cookieDomain: "dev.qonto.co",
      },
      staging: {
        announcementBaseURL:
          "https://firestore.googleapis.com/v1/projects/announcement-staging/databases/(default)/documents",
        apiBaseURL: `https://api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        attachmentCollectorURL: `https://attachment-collector-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        authBaseURL: `https://auth-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        billerBaseURL: `https://biller-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        companyCreationBaseURL: `https://company-creation-de-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        hubBaseURL: `https://hub-api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        notifierBaseURL: `https://notifier-api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        pricingCatalogBaseURL: `https://pricing-catalog-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        registerBaseURL: `https://register-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        registerPartnersBaseURL: `https://partner-register-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        switchingBankApiBaseURL: `https://switching-bank-api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        verificationBaseURL: `https://verification-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        websocketBaseURL: `wss://websocket-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
        landingURL: "https://landing-staging.qonto.co",
        cookieDomain: "dev.qonto.co",
      },
      production: {
        announcementBaseURL:
          "https://firestore.googleapis.com/v1/projects/announcement-dc763/databases/(default)/documents",
        apiBaseURL: "https://api.qonto.com",
        attachmentCollectorURL: `https://attachment-collector.qonto.com`,
        authBaseURL: "https://auth.qonto.com",
        billerBaseURL: "https://biller.qonto.com",
        companyCreationBaseURL: "https://company-creation-de.qonto.com",
        hubBaseURL: "https://hub-api.qonto.com",
        notifierBaseURL: "https://notifier-api.qonto.com",
        pricingCatalogBaseURL: 'https://pricing-catalog.qonto.com',
        registerBaseURL: "https://register.qonto.com",
        registerPartnersBaseURL: "https://partner-register.qonto.com",
        verificationBaseURL: "https://verification.qonto.com",
        switchingBankApiBaseURL: "https://switch-api.qonto.com",
        websocketBaseURL: "wss://websocket.qonto.com",
        landingURL: "https://qonto.com",
        cookieDomain: "qonto.com",
      },
    };

    let frontendUrlsTable = {
      development: {
        companyCreationJsURL: "https://create.dev.qonto.co",
        infogreffeUrl: "https://test4.infogreffe.fr/mes-formalites.html",
        appJsURL: "https://app.dev.qonto.co",
        receivableInvoicesURL: "https://receivable-invoices-js.dev.qonto.co",
        registerJsURL: "https://welcome.dev.qonto.co",
        registerPartnersJsURL: "https://register-partners-js.dev.qonto.co",
        host: "qonto.co",
        switchingBankJsURL: "https://switch.dev.qonto.co",
      },
      staging: {
        companyCreationJsURL: `https://company-creation-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
        infogreffeUrl: "https://test4.infogreffe.fr/mes-formalites.html",
        appJsURL: `https://${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
        receivableInvoicesURL: `https://receivable-invoices-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
        registerJsURL: `https://register-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
        registerPartnersJsURL: `https://register-partners-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
        switchingBankJsURL: `https://switching-bank-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
        host: "qonto.co",
      },
      production: {
        companyCreationJsURL: "https://create.qonto.com",
        infogreffeUrl: "https://www.infogreffe.fr/mes-formalites.html",
        appJsURL: "https://app.qonto.com",
        receivableInvoicesURL: "https://pay.qonto.com",
        registerJsURL: "https://welcome.qonto.com",
        registerPartnersJsURL: "https://partners.qonto.com/",
        host: "qonto.com",
        switchingBankJsURL: `https://switch.qonto.com`,
      },
    };

    return {
      frontendUrlsTable: frontendUrlsTable[frontendUrlMode],
      backendUrlsTable: backendUrlsTable[backendUrlMode],
    };
  },
};
