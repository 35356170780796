import * as React from 'react';
import { clsx } from 'clsx';
import styles from './subscription-header.strict-module.css';

interface SubscriptionHeaderProps {
  title: React.ReactNode;
}

export function SubscriptionHeader({ title }: SubscriptionHeaderProps): React.JSX.Element {
  return (
    <h1 className={clsx(styles.title, 'title-1')} data-test-subscription-header-title>
      {title}
    </h1>
  );
}
