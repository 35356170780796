import cx from 'clsx';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { IconIsometricFlag } from '../../assets/icons';
import type { SupportedLocales } from './supported-locales';
import type { CountryCodes } from './country-codes';
import styles from './flag.strict-module.css';

export type CountryCode = keyof typeof CountryCodes;

type BaseFlagProps = ComponentPropsWithoutRef<'span'>;

interface SquareFlagProps extends BaseFlagProps {
  variant?: 'square';
  code: CountryCode;
}

interface IsometricFlagProps extends BaseFlagProps {
  variant: 'isometric';
  locale: SupportedLocales;
}

type FlagProps = SquareFlagProps | IsometricFlagProps;

function isIsometric(props: SquareFlagProps | IsometricFlagProps): props is IsometricFlagProps {
  return props.variant === 'isometric';
}

export function Flag(props: FlagProps): ReactNode {
  if (isIsometric(props)) {
    const { locale } = props;

    return (
      <span aria-label={locale} data-test-flag="" role="img" {...props}>
        <IconIsometricFlag locale={locale} />
      </span>
    );
  }

  const { code } = props;

  return (
    <span
      aria-label={code}
      className={cx(styles.flag, styles[code.toLowerCase()])}
      data-test-flag=""
      role="img"
      {...props}
    />
  );
}
