"use strict";
/**
 * Get Qonto environment configuration
 *
 * This configuration is evaluated at runtime.
 *
 * @param {string} environment - The current environment (e.g., 'development', 'production', 'test').
 * @returns {Object} The environment configuration object.
 */
module.exports.getQontoEnvConfig = function (environment) {
  let {
    BRANCH_NAME: branchName,
    DEPLOY_TARGET: deployTarget,
    QONTO_BACKEND_BRANCH_NAME = "qa",
    QONTO_BACKEND_USE_LOCAL,
    STAGING_ACCESS_TOKEN,
    QONTO_BACKEND_USE_MIRAGE,
  } = process.env;

  let backendBranchName = QONTO_BACKEND_BRANCH_NAME;

  // Preserved as Backward compatibility
  let useQontoDotEnv = Boolean(QONTO_BACKEND_USE_LOCAL);
  let useQontoEnv = Boolean(QONTO_BACKEND_USE_LOCAL);

  let useMirage = Boolean(QONTO_BACKEND_USE_MIRAGE);

  // Preserved as Backward compatibility
  let isStaging = deployTarget === "staging";
  let isProduction = deployTarget === "production";

  let backendUrlMode = "production";
  let frontendUrlMode = "production";

  if (useQontoDotEnv || useMirage) {
    backendUrlMode = "local";
    frontendUrlMode = "development";
  } else if (deployTarget === "staging") {
    backendUrlMode = "staging";
    frontendUrlMode = "staging";
  } else if (environment === "test") {
    backendUrlMode = "local";
    frontendUrlMode = "development";
  } else if (environment === "development") {
    frontendUrlMode = "development";
    backendUrlMode = "staging";
  }

  let ENV = {
    // base
    environment,
    // qonto
    branchName,
    deployTarget,
    backendBranchName,
    eksClusterId: "",
    monorepoSuffix: "",
    frontendBranchName: "",
    frontendEksClusterId: "",
    frontendUrlMode,
    backendUrlMode,
    useMirage,
    useQontoEnv,
    useQontoDotEnv,
    isStaging,
    isProduction,
  };

  if (isStaging) {
    ENV.stagingAccessToken = STAGING_ACCESS_TOKEN;
  }

  return ENV;
};
