import type { ReactElement } from 'react';
import { createElement } from 'react';
import cx from 'clsx';
import styles from './disclaimer.strict-module.css';
import { InfoIcon } from './icons/info';
import { WarningIcon } from './icons/warning';
import { ErrorIcon } from './icons/error';

export interface DisclaimerProps {
  children: React.ReactNode;
  level?: 'info' | 'warning' | 'error';
  block?: boolean;
  className?: string;
}

// TODO: change alt to translation when i18n
const ICONS = {
  info: { element: InfoIcon, alt: 'Info' },
  warning: { element: WarningIcon, alt: 'Warning' },
  error: { element: ErrorIcon, alt: 'Error' },
};

function DisclaimerComponent({
  children,
  level = 'info',
  block = false,
  className,
}: DisclaimerProps): ReactElement {
  return (
    <div
      className={cx(
        styles.wrapper,
        block && styles.block,
        !block && styles.inline,
        block && styles[`${level}Block`],
        className
      )}
      data-test-disclaimer-block={block}
      data-test-disclaimer-inline={!block}
      data-test-level={level}
    >
      <div className={cx(styles.icon, styles[`${level}Icon`])}>
        {createElement(ICONS[level].element, {
          'aria-label': ICONS[level].alt,
        })}
      </div>
      <p className={cx('body-1', styles.message)} data-test-message>
        {children}
      </p>
    </div>
  );
}

function DisclaimerInline({ level = 'info', className, children }: DisclaimerProps): ReactElement {
  return (
    <DisclaimerComponent block={false} className={className} level={level}>
      {children}
    </DisclaimerComponent>
  );
}

function DisclaimerBlock({ level = 'info', className, children }: DisclaimerProps): ReactElement {
  return (
    <DisclaimerComponent block className={className} level={level}>
      {children}
    </DisclaimerComponent>
  );
}

export const Disclaimer = {
  Inline: DisclaimerInline,
  Block: DisclaimerBlock,
};
