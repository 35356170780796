import type { ReactElement } from 'react';
import type { LinkProps as AriaLinkProps } from 'react-aria-components';
import { Link as AriaLink } from 'react-aria-components';
import cx from 'clsx';
import type { BaseButtonProps } from '../button';
import buttonStyles from '../../primitives/button.strict-module.css';
import linkStyles from './link.strict-module.css';

interface LinkDisplayProps extends AriaLinkProps {
  display?: 'link';
}

interface ButtonDisplayProps extends AriaLinkProps, BaseButtonProps {
  display: 'button';
}

type LinkProps = LinkDisplayProps | ButtonDisplayProps;

export function Link({ children, display = 'link', className, ...props }: LinkProps): ReactElement {
  const isButtonDisplay = display === 'button';
  let classes = cx('body-link', linkStyles.link, className);

  if (isButtonDisplay) {
    const {
      variant = 'primary',
      size = 'medium',
      iconOnly = false,
      stretch = false,
    } = props as ButtonDisplayProps;
    classes = cx(
      'body-1',
      buttonStyles.btn,
      buttonStyles['button-wrapper'],
      variant !== 'primary' && buttonStyles[`btn--${variant}`],
      size !== 'medium' && buttonStyles[`btn--${size}`],
      iconOnly && buttonStyles['btn--icon-only'],
      stretch && buttonStyles['btn--stretch'],
      className
    );
  }

  return (
    <AriaLink className={classes} {...props}>
      {children}
    </AriaLink>
  );
}
