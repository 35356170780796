import type { ReactElement, ReactNode } from 'react';
import type { CheckboxProps as AriaCheckboxProps } from 'react-aria-components';
import { Checkbox as AriaCheckbox } from 'react-aria-components';
import cx from 'clsx';
import { CheckmarkOutlinedCheckboxIcon } from './icons/checkmark-outlined-checkbox';
import { MinusOutlinedCheckboxIcon } from './icons/minus-outlined-checkbox';
import styles from './checkbox.strict-module.css';

interface CheckboxProps extends AriaCheckboxProps {
  children?: ReactNode;
}

export function Checkbox({ className, children, ...props }: CheckboxProps): ReactElement {
  return (
    <AriaCheckbox className={cx(className, styles['checkbox-wrapper'])} {...props}>
      {({ isSelected, isIndeterminate }) => {
        let icon = null;
        if (isSelected) {
          icon = <CheckmarkOutlinedCheckboxIcon />;
        }

        if (isIndeterminate) {
          icon = <MinusOutlinedCheckboxIcon />;
        }

        return (
          <>
            <div className={cx('overlay', styles.checkbox)} data-checkbox>
              {icon}
            </div>
            {children ? (
              <span className={cx('body-2', 'ml-8', styles.label)} data-label>
                {children}
              </span>
            ) : null}
          </>
        );
      }}
    </AriaCheckbox>
  );
}
